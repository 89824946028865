// utils
import dayjs from '@/utils/day';

export default function useCampsiteDetailOperatingHours({
  campsite: pCampsite = null,
}: {
  campsite: MaybeRefOrGetter<any | null>;
}) {
  const campsite = toRef(pCampsite);

  /********************
   * REFS & VARS       *
   ********************/
  const openingRanges = computed<string[]>(() => {
    return campsite.value?.meta?.opening_ranges || [];
  });

  const openingDatesAsStrings = computed<Array<{ start: string; end: string }>>(() => {
    return campsite.value?.meta?.opening_dates || [];
  });

  const openingDatesAsDates = computed(() => {
    return openingDatesAsStrings.value.map((openingDate) => {
      return {
        start: dayjs(openingDate.start),
        end: dayjs(openingDate.end),
      };
    });
  });

  const hasOperatingHours = computed(() => {
    return !(!openingDatesAsStrings.value || !openingDatesAsStrings.value.length);
  });

  const isCurrentlyOpen = computed(() => {
    if (!hasOperatingHours.value) {
      return false;
    }

    const today = dayjs().year(1900);
    return openingRanges.value.reduce((accumulator, currentValue) => {
      if (!accumulator) {
        const start = dayjs(currentValue[0]);
        const end = dayjs(currentValue[1]);
        return dayjs(today).isBetween(start, end, 'day', '[]');
      }
      return accumulator;
    }, false);
  });

  const openings = computed(() => {
    return openingDatesAsStrings.value;
  });

  return {
    hasOperatingHours,
    isCurrentlyOpen,
    openingDatesAsDates,
    openingDatesAsStrings,
    openingRanges,
    openings,
  };
}
